import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./style.scss"
import { message } from 'antd';
import { isMobile } from 'react-device-detect';

const HomePage = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 1);
        });
    }, []);
    const [showSnow, setShowSnow] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setShowSnow(true);
            } else {
                setShowSnow(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    const data = [
        './images/g-1.png',
        './images/g-2.png',
        './images/g-3.png',
        './images/g-4.png',
        './images/g-5.png',
        './images/g-6.png',
        './images/g-7.png',
        './images/g-8.png',
        './images/g-9.png',
        './images/g-10.png',
        './images/g-11.png',
        './images/g-12.png',
    ]

    const [randomImage, setRandomImage] = useState('./images/g-1.png');

    const getRandomImage: any = () => {
        const randomIndex = Math.floor(Math.random() * data.length);
        return data[randomIndex];
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRandomImage(getRandomImage());
        }, 2000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <div className="snowflakes" aria-hidden="true">
                <div className="snowflake"><img src="./images/r-1.svg" alt="" /></div>
                <div className="snowflake"><img src="./images/r-2.svg" alt="" /></div>
                <div className="snowflake"><img src="./images/r-3.svg" alt="" /></div>
                <div className="snowflake"><img src="./images/r-4.svg" alt="" /></div>
                <div className="snowflake"><img src="./images/r-5.svg" alt="" /></div>
                <div className="snowflake"><img src="./images/r-6.svg" alt="" /></div>
                <div className="snowflake"><img src="./images/r-7.svg" alt="" /></div>
                <div className="snowflake"><img src="./images/r-8.svg" alt="" /></div>
                <div className="snowflake"><img src="./images/r-9.svg" alt="" /></div>
                <div className="snowflake"><img src="./images/r-1.svg" alt="" /></div>
                <div className="snowflake"><img src="./images/rocs.png" alt="" /></div>
                <div className="snowflake"><img src="./images/rocs.png" alt="" /></div>
            </div>
            <header className={scroll ? "header background" : "header"}>
                <div className="container">
                    <div className="main-header">
                        <div className="main-menu">
                            <ul className="list-menu">
                                <li>
                                    <a href="#about">ABOUT US</a>
                                </li>
                                <li>
                                    <a href="https://solscan.io/token/7Akh51JvZDvEi9a5KLHkkEfnajJzqRmnK2jVUvW1XRPA" target='blank'>LINK</a>
                                </li>
                                <li>
                                    <a href="#">CONTACT</a>
                                </li>
                                <li>
                                    <a href="https://raydium.io/swap/?outputMint=7Akh51JvZDvEi9a5KLHkkEfnajJzqRmnK2jVUvW1XRPA&inputMint=sol" target='blank'>BUY</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <section className="section-banner">
                <div className="container">
                    <div className="main-banner">
                        <div className="title">
                            $chilly
                        </div>
                        {/* <div className="desc">
                            Chilly Cute Puppy
                        </div> */}
                        <div className="box-img">
                            <img src="./images/chilly-full.png" alt="" />
                        </div>
                        <div className="contract-change">
                            <div className="row-contract">
                                {!isMobile ? "7Akh51JvZDvEi9a5KLHkkEfnajJzqRmnK2jVUvW1XRPA" : "7Akh...XRPA"}
                                <CopyToClipboard
                                    text={"7Akh51JvZDvEi9a5KLHkkEfnajJzqRmnK2jVUvW1XRPA"}
                                    onCopy={() => {
                                        message.success("Copied", 0.4);
                                    }}
                                >
                                    <span>Copy</span>
                                </CopyToClipboard>
                            </div>
                        </div>
                        <div className="list-social">
                            <div className="item">
                                <a href="https://t.me/SolanaChilly" target='blank'>
                                    <img src="./images/ss-1.png" alt="" />
                                </a>
                            </div>
                            <div className="item">
                                <a href="https://x.com/SolanaChilly" target='blank'>
                                    <img src="./images/ss-2.png" alt="" />
                                </a>
                            </div>
                            <div className="item">
                                <a href="https://dexscreener.com/solana/93mz9xlpynzgnmqnkzkrxuydnx8m6e6tmryhgsunyrvn" target='blank'>
                                    <img src="./images/ss-3.png" alt="" />
                                </a>
                            </div>
                            <div className="item">
                                <a href="https://www.dextools.io/app/en/solana/pair-explorer/93mz9XLPYNzGNmQnKZkRXUYDnx8M6E6TMryHGsUNYrVn?t=1720336491091" target='blank'>
                                    <img src="./images/ss-4.png" alt="" />
                                </a>
                            </div>
                            <div className="item">
                                <a href="https://birdeye.so/token/7Akh51JvZDvEi9a5KLHkkEfnajJzqRmnK2jVUvW1XRPA?chain=solana" target='blank'>
                                    <img src="./images/ss-5.png" alt="" />
                                </a>
                            </div>
                            <div className="item">
                                <img src="./images/ss-6.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="./images/ss-7.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-chilly">
                <div className="container">
                    <div className="main-chilly">
                        <div className="left">
                            <div className="box-img">
                                <img src="./images/chilly.png" alt="" />
                            </div>
                        </div>
                        <div className="right">
                            <div className="content-chilly">
                                <div className="title">
                                    CHILLY Love
                                </div>
                                <div className="number">
                                    12,905 <img src="./images/smo.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="section-bought-chilly" id="about">
                <div className="container">
                    <div className="main-bought-chilly">
                        <div className="left">
                            <div className="box-img">
                                <img src={randomImage} alt="" />
                            </div>
                        </div>
                        <div className="right">
                            <div className="content-bougth">
                                <div className="desc">
                                    Hi, I'm $CHILLY, the coolest pup on Solana! <br />
                                    Let's spread the joy, spread $CHILLY! <br />
                                    $CHILLY in the multiverse
                                </div>
                                <div className="gr-button">
                                    <a href="https://raydium.io/swap/?outputMint=7Akh51JvZDvEi9a5KLHkkEfnajJzqRmnK2jVUvW1XRPA&inputMint=sol" target='blank'>
                                        <button className="buy-chilly">
                                            Buy $Chilly
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-tokenomic">
                <div className="container">
                    <div className="main-tokenomic">
                        <div className="title">
                            Tokenomics
                        </div>
                        <div className="box-img">
                            <img src="./images/token.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-chilly-gallery">
                <div className="container">
                    <div className="main-chilly-gallery">
                        <div className="title">
                            chilly cute puppy
                        </div>
                        <div className="columns">
                            <div className="colum w-3">
                                <div className="box-img">
                                    <img src="./images/g-1.png" alt="" />
                                </div>
                            </div>
                            <div className="colum w-3">
                                <div className="box-img">
                                    <img src="./images/g-2.png" alt="" />
                                </div>
                            </div>
                            <div className="colum w-3">
                                <div className="box-img">
                                    <img src="./images/g-3.png" alt="" />
                                </div>
                            </div>
                            <div className="colum w-3">
                                <div className="box-img">
                                    <img src="./images/g-4.png" alt="" />
                                </div>
                            </div>
                            <div className="colum w-3">
                                <div className="box-img">
                                    <img src="./images/g-5.png" alt="" />
                                </div>
                            </div>
                            <div className="colum w-3">
                                <div className="box-img">
                                    <img src="./images/g-6.png" alt="" />
                                </div>
                            </div>
                            <div className="colum w-3">
                                <div className="box-img">
                                    <img src="./images/g-7.png" alt="" />
                                </div>
                            </div>
                            <div className="colum w-3">
                                <div className="box-img">
                                    <img src="./images/g-8.png" alt="" />
                                </div>
                            </div>
                            <div className="colum w-3">
                                <div className="box-img">
                                    <img src="./images/g-9.png" alt="" />
                                </div>
                            </div>
                            <div className="colum w-3">
                                <div className="box-img">
                                    <img src="./images/g-10.png" alt="" />
                                </div>
                            </div>
                            <div className="colum w-3">
                                <div className="box-img">
                                    <img src="./images/g-11.png" alt="" />
                                </div>
                            </div>
                            <div className="colum w-3">
                                <div className="box-img">
                                    <img src="./images/g-12.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-hows">
                <div className="container">
                    <div className="main-hows">
                        <div className="title">
                            How to buy
                        </div>
                        <div className="group-hows">
                            <div className="item">
                                <div className="left">
                                    <div className="number">
                                        01
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="text-row">
                                        <div className="txt">
                                            Download Phantom Extension
                                        </div>
                                        <div className="desc">
                                            Download and install phantom on the appstore, or, alternatively if you’re on desktop download and install the browser extension
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="left">
                                    <div className="number">
                                        02
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="text-row">
                                        <div className="txt">
                                            Secure your SOL
                                        </div>
                                        <div className="desc">
                                            Now all you’ve got to do is buy your SOL in the Phantom App, or, alternatively you can use an exchange and deposit using your wallet address
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="left">
                                    <div className="number">
                                        03
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="text-row">
                                        <div className="txt">
                                            Buy some $CHILLY
                                        </div>
                                        <div className="desc">
                                            Now go to Raydium and paste the $cHILLY contract address to swap your SOL
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="left">
                                    <div className="number">
                                        04
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="text-row">
                                        <div className="txt">
                                            Add to your wallet
                                        </div>
                                        <div className="desc">
                                            Now you’re all set! Welcome aboard the next rocket to the moon, just import the contract address to view you’re holdings and you can track your earnings.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="footer">
                <div className="container">
                    <div className="main-footer">
                        <div className="box-img">
                            <img src="./images/mian-icon.png" alt="" />
                        </div>
                        <div className="copy-right">
                            @ 2024 all rights reserved!
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default HomePage